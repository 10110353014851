// Here you can add other styles
.body {
  display: flex;
  align-items: center;
  align-self: center;
  width: 100%;
  justify-content: center;
  background-color: #ecefee;
  // background-image: url("../assets/fond.jpg");
  // background-size: cover;
}

.sidebar-nav {
  //justify-content: center;
  margin-top: 50px;
}

.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3 !important;
}

// .MuiTabs-indicator {
//   display: none !important;
// }

.p-tabview .p-tabview-panels {
  padding: 0 !important;
}

// .MuiSvgIcon-root {
//   position: fixed;
//   top: 350px;
//   font-size: 2rem !important;
//   // height: 80px;
//   // width: auto;
// }

// .MuiTabs-root {
//   background-color: #ecefee;
//   padding-top: 1.25rem;
// }

.p-tabview-nav-container {
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.min-vh-max {
  min-height: calc(100vh - 40px);
}
