.avatarXs {
  width: 1rem !important;
  height: 1rem !important;
  border-radius: 0 !important;
}

.dropdown {
  z-index: 2000;
  position: absolute;
}
