// Variables

// If you want to customize your project please uncomment and update one of the following variables.

// Color system
$dark-main-bg: #181a1b;
//$dark-main-txt: rgb(195, 190, 182);
$dark-main-txt: rgb(60, 65, 68);
// scss-docs-start gray-color-variables
$dark-white: #fff !default;
$dark-gray-base: #111926 !default;
$dark-gray-100: #111d29 !default;
$dark-gray-200: #222e42 !default;
$dark-gray-300: #546378 !default;
$dark-gray-400: #b1b7c1 !default;
$dark-gray-500: #9da5b1 !default;
$dark-gray-600: #8a93a2 !default;
$dark-gray-700: #768192 !default;
$dark-gray-800: #ced5e1 !default;
$dark-gray-900: #dee2e8 !default;
$dark-black: #000015 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$dark-grays: (
  '100': $dark-gray-100,
  '200': $dark-gray-200,
  '300': $dark-gray-300,
  '400': $dark-gray-400,
  '500': $dark-gray-500,
  '600': $dark-gray-600,
  '700': $dark-gray-700,
  '800': $dark-gray-800,
  '900': $dark-gray-900,
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

$dark-high-emphasis: rgba($dark-white, 0.95) !default;
$dark-medium-emphasis: rgba($dark-white, 0.681) !default;
$dark-disabled: rgba($dark-gray-base, 0.38) !default;

$dark-high-emphasis-inverse: rgba($dark-white, 0.87) !default;
$dark-medium-emphasis-inverse: rgba($dark-white, 0.6) !default;
$dark-disabled-inverse: rgba($dark-white, 0.38) !default;

// scss-docs-start color-variables
$dark-blue: #0d6efd !default;
$dark-indigo: #6610f2 !default;
$dark-purple: #6f42c1 !default;
$dark-pink: #d63384 !default;
$dark-red: #dc3545 !default;
$dark-orange: #fd7e14 !default;
$dark-yellow: #ffc107 !default;
$dark-green: #198754 !default;
$dark-teal: #20c997 !default;
$dark-cyan: #0dcaf0 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$dark-colors: (
  'blue': $dark-blue,
  'indigo': $dark-indigo,
  'purple': $dark-purple,
  'pink': $dark-pink,
  'red': $dark-red,
  'orange': $dark-orange,
  'yellow': $dark-yellow,
  'green': $dark-green,
  'teal': $dark-teal,
  'cyan': $dark-cyan,
  'white': $dark-white,
  'gray': $dark-gray-600,
  'gray-dark': $dark-gray-800,
) !default;
// scss-docs-end colors-map

// fusv-disable
$dark-primary-dark: #1f1498 !default;
$dark-primary-base: #321fdb !default;
$dark-primary-50: #988fed !default;
$dark-primary-25: #ccc7f6 !default;

$dark-secondary-dark: #212233 !default;
$dark-secondary-base: #9da5b1 !default;
$dark-secondary-50: #9da5b1 !default;
$dark-secondary-25: #ced2d8 !default;

$dark-success-dark: #1b9e3e !default;
$dark-success-base: #2eb85c !default;
$dark-success-50: #96dbad !default;
$dark-success-25: #cbedd6 !default;

$dark-info-dark: #2982cc !default;
$dark-info-base: #39f !default;
$dark-info-50: #80c6ff !default;
$dark-info-25: #c0e6ff !default;

$dark-warning-dark: #f6960b !default;
$dark-warning-base: #f9b115 !default;
$dark-warning-50: #fcd88a !default;
$dark-warning-25: #feecc5 !default;

$dark-danger-dark: #d93737 !default;
$dark-danger-base: #e55353 !default;
$dark-danger-50: #f2a9a9 !default;
$dark-danger-25: #f9d4d4 !default;

$dark-light-dark: $dark-gray-100 !default;
$dark-light-base: $dark-gray-100 !default;
$dark-light-50: shift-color($dark-light-base, -70%) !default;
$dark-light-25: shift-color($dark-light-base, -80%) !default;

$dark-dark-dark: $dark-gray-900 !default;
$dark-dark-base: $dark-gray-900 !default;
$dark-dark-50: shift-color($dark-dark-base, -70%) !default;
$dark-dark-25: shift-color($dark-dark-base, -80%) !default;
// fusv-enable

// scss-docs-start theme-color-variables
$dark-primary: $dark-primary-base !default;
$dark-secondary: $dark-secondary-base !default;
$dark-success: $dark-success-base !default;
$dark-info: $dark-info-base !default;
$dark-warning: $dark-warning-base !default;
$dark-danger: $dark-danger-base !default;
$dark-light: $dark-light-base !default;
$dark-dark: $dark-dark-base !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$dark-theme-colors: (
  'primary': $dark-primary,
  'secondary': $dark-secondary,
  'success': $dark-success,
  'info': $dark-info,
  'warning': $dark-warning,
  'danger': $dark-danger,
  'light': $dark-light,
  'dark': $dark-dark,
) !default;
// scss-docs-end theme-colors-map

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$dark-min-contrast-ratio: 4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$dark-color-contrast-dark: $dark-high-emphasis-inverse !default;
$dark-color-contrast-light: $dark-high-emphasis !default;

// fusv-disable
$dark-blue-100: tint-color($dark-blue, 80%) !default;
$dark-blue-200: tint-color($dark-blue, 60%) !default;
$dark-blue-300: tint-color($dark-blue, 40%) !default;
$dark-blue-400: tint-color($dark-blue, 20%) !default;
$dark-blue-500: $dark-blue !default;
$dark-blue-600: shade-color($dark-blue, 20%) !default;
$dark-blue-700: shade-color($dark-blue, 40%) !default;
$dark-blue-800: shade-color($dark-blue, 60%) !default;
$dark-blue-900: shade-color($dark-blue, 80%) !default;

$dark-indigo-100: tint-color($dark-indigo, 80%) !default;
$dark-indigo-200: tint-color($dark-indigo, 60%) !default;
$dark-indigo-300: tint-color($dark-indigo, 40%) !default;
$dark-indigo-400: tint-color($dark-indigo, 20%) !default;
$dark-indigo-500: $dark-indigo !default;
$dark-indigo-600: shade-color($dark-indigo, 20%) !default;
$dark-indigo-700: shade-color($dark-indigo, 40%) !default;
$dark-indigo-800: shade-color($dark-indigo, 60%) !default;
$dark-indigo-900: shade-color($dark-indigo, 80%) !default;

$dark-purple-100: tint-color($dark-purple, 80%) !default;
$dark-purple-200: tint-color($dark-purple, 60%) !default;
$dark-purple-300: tint-color($dark-purple, 40%) !default;
$dark-purple-400: tint-color($dark-purple, 20%) !default;
$dark-purple-500: $dark-purple !default;
$dark-purple-600: shade-color($dark-purple, 20%) !default;
$dark-purple-700: shade-color($dark-purple, 40%) !default;
$dark-purple-800: shade-color($dark-purple, 60%) !default;
$dark-purple-900: shade-color($dark-purple, 80%) !default;

$dark-pink-100: tint-color($dark-pink, 80%) !default;
$dark-pink-200: tint-color($dark-pink, 60%) !default;
$dark-pink-300: tint-color($dark-pink, 40%) !default;
$dark-pink-400: tint-color($dark-pink, 20%) !default;
$dark-pink-500: $dark-pink !default;
$dark-pink-600: shade-color($dark-pink, 20%) !default;
$dark-pink-700: shade-color($dark-pink, 40%) !default;
$dark-pink-800: shade-color($dark-pink, 60%) !default;
$dark-pink-900: shade-color($dark-pink, 80%) !default;

$dark-red-100: tint-color($dark-red, 80%) !default;
$dark-red-200: tint-color($dark-red, 60%) !default;
$dark-red-300: tint-color($dark-red, 40%) !default;
$dark-red-400: tint-color($dark-red, 20%) !default;
$dark-red-500: $dark-red !default;
$dark-red-600: shade-color($dark-red, 20%) !default;
$dark-red-700: shade-color($dark-red, 40%) !default;
$dark-red-800: shade-color($dark-red, 60%) !default;
$dark-red-900: shade-color($dark-red, 80%) !default;

$dark-orange-100: tint-color($dark-orange, 80%) !default;
$dark-orange-200: tint-color($dark-orange, 60%) !default;
$dark-orange-300: tint-color($dark-orange, 40%) !default;
$dark-orange-400: tint-color($dark-orange, 20%) !default;
$dark-orange-500: $dark-orange !default;
$dark-orange-600: shade-color($dark-orange, 20%) !default;
$dark-orange-700: shade-color($dark-orange, 40%) !default;
$dark-orange-800: shade-color($dark-orange, 60%) !default;
$dark-orange-900: shade-color($dark-orange, 80%) !default;

$dark-yellow-100: tint-color($dark-yellow, 80%) !default;
$dark-yellow-200: tint-color($dark-yellow, 60%) !default;
$dark-yellow-300: tint-color($dark-yellow, 40%) !default;
$dark-yellow-400: tint-color($dark-yellow, 20%) !default;
$dark-yellow-500: $dark-yellow !default;
$dark-yellow-600: shade-color($dark-yellow, 20%) !default;
$dark-yellow-700: shade-color($dark-yellow, 40%) !default;
$dark-yellow-800: shade-color($dark-yellow, 60%) !default;
$dark-yellow-900: shade-color($dark-yellow, 80%) !default;

$dark-green-100: tint-color($dark-green, 80%) !default;
$dark-green-200: tint-color($dark-green, 60%) !default;
$dark-green-300: tint-color($dark-green, 40%) !default;
$dark-green-400: tint-color($dark-green, 20%) !default;
$dark-green-500: $dark-green !default;
$dark-green-600: shade-color($dark-green, 20%) !default;
$dark-green-700: shade-color($dark-green, 40%) !default;
$dark-green-800: shade-color($dark-green, 60%) !default;
$dark-green-900: shade-color($dark-green, 80%) !default;

$dark-teal-100: tint-color($dark-teal, 80%) !default;
$dark-teal-200: tint-color($dark-teal, 60%) !default;
$dark-teal-300: tint-color($dark-teal, 40%) !default;
$dark-teal-400: tint-color($dark-teal, 20%) !default;
$dark-teal-500: $dark-teal !default;
$dark-teal-600: shade-color($dark-teal, 20%) !default;
$dark-teal-700: shade-color($dark-teal, 40%) !default;
$dark-teal-800: shade-color($dark-teal, 60%) !default;
$dark-teal-900: shade-color($dark-teal, 80%) !default;

$dark-cyan-100: tint-color($dark-cyan, 80%) !default;
$dark-cyan-200: tint-color($dark-cyan, 60%) !default;
$dark-cyan-300: tint-color($dark-cyan, 40%) !default;
$dark-cyan-400: tint-color($dark-cyan, 20%) !default;
$dark-cyan-500: $dark-cyan !default;
$dark-cyan-600: shade-color($dark-cyan, 20%) !default;
$dark-cyan-700: shade-color($dark-cyan, 40%) !default;
$dark-cyan-800: shade-color($dark-cyan, 60%) !default;
$dark-cyan-900: shade-color($dark-cyan, 80%) !default;
// fusv-enable

// Characters which are escaped by the escape-svg function
$dark-escaped-characters: (
  ('<', '%3c'),
  ('>', '%3e'),
  ('#', '%23'),
  ('(', '%28'),
  (')', '%29')
) !default;

// Options

// Quickly modify global styling by enabling or disabling optional features.

$dark-enable-caret: true !default;
$dark-enable-rounded: true !default;
$dark-enable-shadows: false !default;
$dark-enable-gradients: false !default;
$dark-enable-transitions: true !default;
$dark-enable-reduced-motion: true !default;
$dark-enable-smooth-scroll: true !default;
$dark-enable-grid-classes: true !default;
$dark-enable-button-pointers: true !default;
$dark-enable-rfs: true !default;
$dark-enable-validation-icons: true !default;
$dark-enable-negative-margins: false !default;
$dark-enable-deprecation-messages: true !default;
$dark-enable-important-utilities: true !default;
$dark-enable-contrast-ratio-correction: true !default;
$dark-enable-contrast-ratio-warnings: false !default;
$dark-enable-ltr: true !default;
$dark-enable-rtl: false !default;

// Set mobile breakpoint

$dark-mobile-breakpoint: md !default;

// Prefix for :root CSS variables

$dark-variable-prefix: cui- !default;

// Gradient

// The gradient which is added to components if `$dark-enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
// scss-docs-start variable-gradient
$dark-gradient: linear-gradient(
  180deg,
  rgba($dark-white, 0.15),
  rgba($dark-white, 0)
) !default;
// scss-docs-end variable-gradient

// Spacing

// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $dark-spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$dark-spacer: 1rem !default;
$dark-spacers: (
  0: 0,
  1: calc($dark-spacer / 4),
  2: calc($dark-spacer / 2),
  3: $dark-spacer,
  4: $dark-spacer * 1.5,
  5: $dark-spacer * 3,
) !default;

$dark-negative-spacers: if(
  $dark-enable-negative-margins,
  negativify-map($dark-spacers),
  null
) !default;
// scss-docs-end spacer-variables-maps

// Position

// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
$dark-position-values: (
  0: 0,
  50: 50%,
  100: 100%,
) !default;
// scss-docs-end position-map

// Body

// Settings for the `<body>` element.

$dark-body-bg: $dark-white !default;
$dark-body-color: $dark-high-emphasis !default;
$dark-body-text-align: null !default;

// Links

// Style anchor elements.

$dark-link-color: $dark-primary !default;
$dark-link-decoration: underline !default;
$dark-link-shade-percentage: 20% !default;
$dark-link-hover-color: shift-color(
  $dark-link-color,
  $dark-link-shade-percentage
) !default;
$dark-link-hover-decoration: null !default;

$dark-stretched-link-pseudo-element: after !default;
$dark-stretched-link-z-index: 1 !default;

// Paragraphs

// Style p element.

$dark-paragraph-margin-bottom: 1rem !default;

// Grid breakpoints

// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$dark-grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;
// scss-docs-end grid-breakpoints

// @include _assert-ascending($dark-grid-breakpoints, "$dark-grid-breakpoints");
// @include _assert-starts-at-zero($dark-grid-breakpoints, "$dark-grid-breakpoints");

// Grid containers

// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$dark-container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
) !default;
// scss-docs-end container-max-widths

// @include _assert-ascending($dark-container-max-widths, "$dark-container-max-widths");

// Grid columns

// Set the number of columns and specify the width of the gutters.

$dark-grid-columns: 12 !default;
$dark-grid-gutter-width: 1.5rem !default;
$dark-grid-row-columns: 6 !default;

$dark-gutters: $dark-spacers !default;

// Container padding

$dark-container-padding-x: calc($dark-grid-gutter-width / 2) !default;

// Components

// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$dark-border-width: 1px !default;
$dark-border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
) !default;

$dark-border-color: $dark-gray-200 !default;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$dark-border-radius: 0.25rem !default;
$dark-border-radius-sm: 0.2rem !default;
$dark-border-radius-lg: 0.3rem !default;
$dark-border-radius-pill: 50rem !default;
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
$dark-box-shadow: 0 0.5rem 1rem rgba($dark-black, 0.15) !default;
$dark-box-shadow-sm: 0 0.125rem 0.25rem rgba($dark-black, 0.075) !default;
$dark-box-shadow-lg: 0 1rem 3rem rgba($dark-black, 0.175) !default;
$dark-box-shadow-inset: inset 0 1px 2px rgba($dark-black, 0.075) !default;
// scss-docs-end box-shadow-variables

$dark-component-active-color: $dark-high-emphasis-inverse !default;
$dark-component-active-bg: $dark-primary !default;

// scss-docs-start caret-variables
$dark-caret-width: 0.3em !default;
$dark-caret-vertical-align: $dark-caret-width * 0.85 !default;
$dark-caret-spacing: $dark-caret-width * 0.85 !default;
// scss-docs-end caret-variables

$dark-transition-base: all 0.2s ease-in-out !default;
$dark-transition-fade: opacity 0.15s linear !default;
// scss-docs-start collapse-transition
$dark-transition-collapse: height 0.35s ease !default;
// scss-docs-end collapse-transition

// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
$dark-aspect-ratios: (
  '1x1': 100%,
  '4x3': calc(3 / 4 * 100%),
  '16x9': calc(9 / 16 * 100%),
  '21x9': calc(9 / 21 * 100%),
) !default;
// scss-docs-end aspect-ratios
// stylelint-enable function-disallowed-list

// Typography

// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$dark-font-family-sans-serif:
  system-ui,
  -apple-system,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  Arial,
  'Noto Sans',
  'Liberation Sans',
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji' !default;
$dark-font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  'Liberation Mono', 'Courier New', monospace !default;
// stylelint-enable value-keyword-case
$dark-font-family-base: var(--#{$dark-variable-prefix}font-sans-serif) !default;
$dark-font-family-code: var(--#{$dark-variable-prefix}font-monospace) !default;

// $dark-font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $dark-font-size-base effects the font size of the body text
$dark-font-size-root: null !default;
$dark-font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$dark-font-size-sm: $dark-font-size-base * 0.875 !default;
$dark-font-size-lg: $dark-font-size-base * 1.25 !default;

$dark-font-weight-lighter: lighter !default;
$dark-font-weight-light: 300 !default;
$dark-font-weight-normal: 400 !default;
$dark-font-weight-medium: 500 !default;
$dark-font-weight-semibold: 600 !default;
$dark-font-weight-bold: 700 !default;
$dark-font-weight-bolder: bolder !default;

$dark-font-weight-base: $dark-font-weight-normal !default;

$dark-line-height-base: 1.5 !default;
$dark-line-height-sm: 1.25 !default;
$dark-line-height-lg: 2 !default;

$dark-h1-font-size: $dark-font-size-base * 2.5 !default;
$dark-h2-font-size: $dark-font-size-base * 2 !default;
$dark-h3-font-size: $dark-font-size-base * 1.75 !default;
$dark-h4-font-size: $dark-font-size-base * 1.5 !default;
$dark-h5-font-size: $dark-font-size-base * 1.25 !default;
$dark-h6-font-size: $dark-font-size-base !default;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$dark-font-sizes: (
  1: $dark-h1-font-size,
  2: $dark-h2-font-size,
  3: $dark-h3-font-size,
  4: $dark-h4-font-size,
  5: $dark-h5-font-size,
  6: $dark-h6-font-size,
) !default;
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$dark-headings-margin-bottom: calc($dark-spacer / 2) !default;
$dark-headings-font-family: null !default;
$dark-headings-font-style: null !default;
$dark-headings-font-weight: 500 !default;
$dark-headings-line-height: 1.2 !default;
$dark-headings-color: unset !default;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$dark-display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
) !default;

$dark-display-font-weight: 300 !default;
$dark-display-line-height: $dark-headings-line-height !default;
// scss-docs-end display-headings

// scss-docs-start type-variables
$dark-lead-font-size: $dark-font-size-base * 1.25 !default;
$dark-lead-font-weight: 300 !default;

$dark-small-font-size: 0.875em !default;

$dark-sub-sup-font-size: 0.75em !default;

$dark-text-high-emphasis: $dark-high-emphasis !default;
$dark-text-medium-emphasis: $dark-medium-emphasis !default;
$dark-text-disabled: $dark-disabled !default;
$dark-text-muted: $dark-disabled !default;

$dark-text-high-emphasis-inverse: $dark-high-emphasis-inverse !default;
$dark-text-medium-emphasis-inverse: $dark-medium-emphasis-inverse !default;
$dark-text-disabled-inverse: $dark-disabled-inverse !default;

$dark-initialism-font-size: $dark-small-font-size !default;

$dark-blockquote-margin-y: $dark-spacer !default;
$dark-blockquote-font-size: $dark-font-size-base * 1.25 !default;
$dark-blockquote-footer-color: $dark-gray-600 !default;
$dark-blockquote-footer-font-size: $dark-small-font-size !default;

$dark-hr-margin-y: $dark-spacer !default;
$dark-hr-color: inherit !default;
$dark-hr-height: $dark-border-width !default;
$dark-hr-opacity: 0.25 !default;

$dark-vr-color: inherit !default;
$dark-vr-width: $dark-border-width !default;
$dark-vr-opacity: 0.25 !default;

$dark-legend-margin-bottom: 0.5rem !default;
$dark-legend-font-size: 1.5rem !default;
$dark-legend-font-weight: null !default;

$dark-mark-padding: 0.2em !default;

$dark-dt-font-weight: $dark-font-weight-bold !default;

$dark-nested-kbd-font-weight: $dark-font-weight-bold !default;

$dark-list-inline-padding: 0.5rem !default;

$dark-mark-bg: #fcf8e3 !default;
// scss-docs-end type-variables

// Icons
$dark-icon-size-base: 1rem !default;
$dark-icon-size-sm: $dark-icon-size-base * 0.875 !default;
$dark-icon-size-lg: $dark-icon-size-base * 1.25 !default;
$dark-icon-size-xl: $dark-icon-size-base * 1.5 !default;

// // Tables

// // Customizes the `.table` component with basic values, each used across all table variations.

// // scss-docs-start table-variables
// $dark-table-cell-padding-y: 0.5rem !default;
// $dark-table-cell-padding-x: 0.5rem !default;
// $dark-table-cell-padding-y-sm: 0.25rem !default;
// $dark-table-cell-padding-x-sm: 0.25rem !default;

// $dark-table-cell-vertical-align: top !default;

// $dark-table-color: $dark-body-color !default;
// $dark-table-bg: transparent !default;

// $dark-table-th-font-weight: 600 !default;

// $dark-table-striped-color: $dark-table-color !default;
// $dark-table-striped-bg-factor: 0.05 !default;
// $dark-table-striped-bg: rgba($dark-black, $dark-table-striped-bg-factor) !default;

// $dark-table-active-color: $dark-table-color !default;
// $dark-table-active-bg-factor: 0.1 !default;
// $dark-table-active-bg: rgba($dark-black, $dark-table-active-bg-factor) !default;

// $dark-table-hover-color: $dark-table-color !default;
// $dark-table-hover-bg-factor: 0.075 !default;
// $dark-table-hover-bg: rgba($dark-black, $dark-table-hover-bg-factor) !default;

// $dark-table-border-factor: 0.1 !default;
// $dark-table-border-width: $dark-border-width !default;
// $dark-table-border-color: $dark-border-color !default;

// $dark-table-striped-order: odd !default;

// $dark-table-group-separator-color: currentColor !default;

// $dark-table-caption-color: $dark-text-muted !default;

// $dark-table-bg-scale: -80% !default;
// // scss-docs-end table-variables

// // scss-docs-start table-loop
// $dark-table-variants: (
//   "primary": table-color-map(shift-color($dark-primary, $dark-table-bg-scale)),
//   "secondary": table-color-map(shift-color($dark-secondary, $dark-table-bg-scale)),
//   "success": table-color-map(shift-color($dark-success, $dark-table-bg-scale)),
//   "danger": table-color-map(shift-color($dark-danger, $dark-table-bg-scale)),
//   "warning": table-color-map(shift-color($dark-warning, $dark-table-bg-scale)),
//   "info": table-color-map(shift-color($dark-info, $dark-table-bg-scale)),
//   "light": table-color-map(shift-color($dark-light, $dark-table-bg-scale)),
//   "dark": table-color-map(shift-color($dark-dark, $dark-table-bg-scale)),
// ) !default;
// scss-docs-end table-loop

// // Buttons + Forms

// // Shared variables that are reassigned to `$dark-input-` and `$dark-btn-` specific variables.

// // scss-docs-start input-btn-variables
$dark-input-btn-padding-y: 0.375rem !default;
$dark-input-btn-padding-x: 0.75rem !default;
$dark-input-btn-font-family: null !default;
$dark-input-btn-font-size: $dark-font-size-base !default;
$dark-input-btn-line-height: $dark-line-height-base !default;

$dark-input-btn-focus-width: 0.25rem !default;
$dark-input-btn-focus-color-opacity: 0.25 !default;
$dark-input-btn-focus-color: rgba(
  $dark-component-active-bg,
  $dark-input-btn-focus-color-opacity
) !default;
$dark-input-btn-focus-blur: 0 !default;
$dark-input-btn-focus-box-shadow: 0 0 $dark-input-btn-focus-blur
  $dark-input-btn-focus-width $dark-input-btn-focus-color !default;

$dark-input-btn-padding-y-sm: 0.25rem !default;
$dark-input-btn-padding-x-sm: 0.5rem !default;
$dark-input-btn-font-size-sm: $dark-font-size-sm !default;

$dark-input-btn-padding-y-lg: 0.5rem !default;
$dark-input-btn-padding-x-lg: 1rem !default;
$dark-input-btn-font-size-lg: $dark-font-size-lg !default;

$dark-input-btn-border-width: $dark-border-width !default;
// scss-docs-end input-btn-variables

// // Buttons

// // For each of Bootstrap's buttons, define text, background, and border color.

// // scss-docs-start btn-variables
// $dark-btn-padding-y: $dark-input-btn-padding-y !default;
// $dark-btn-padding-x: $dark-input-btn-padding-x !default;
// $dark-btn-font-family: $dark-input-btn-font-family !default;
// $dark-btn-font-size: $dark-input-btn-font-size !default;
// $dark-btn-line-height: $dark-input-btn-line-height !default;
// $dark-btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping

// $dark-btn-padding-y-sm: $dark-input-btn-padding-y-sm !default;
// $dark-btn-padding-x-sm: $dark-input-btn-padding-x-sm !default;
// $dark-btn-font-size-sm: $dark-input-btn-font-size-sm !default;

// $dark-btn-padding-y-lg: $dark-input-btn-padding-y-lg !default;
// $dark-btn-padding-x-lg: $dark-input-btn-padding-x-lg !default;
// $dark-btn-font-size-lg: $dark-input-btn-font-size-lg !default;

// $dark-btn-border-width: $dark-input-btn-border-width !default;

$dark-btn-font-weight: $dark-font-weight-normal !default;
$dark-btn-box-shadow:
  inset 0 1px 0 rgba($dark-white, 0.15),
  0 1px 1px rgba($dark-black, 0.075) !default;
$dark-btn-focus-width: $dark-input-btn-focus-width !default;
$dark-btn-focus-box-shadow: $dark-input-btn-focus-box-shadow !default;
$dark-btn-disabled-opacity: 0.65 !default;
$dark-btn-active-box-shadow: inset 0 3px 5px rgba($dark-black, 0.125) !default;

// $dark-btn-link-color: $dark-link-color !default;
// $dark-btn-link-hover-color: $dark-link-hover-color !default;
// $dark-btn-link-disabled-color: $dark-gray-600 !default;

// Allows for customizing button radius independently from global border radius
$dark-btn-border-radius: $dark-border-radius !default;
$dark-btn-border-radius-sm: $dark-border-radius-sm !default;
$dark-btn-border-radius-lg: $dark-border-radius-lg !default;

$dark-btn-transition:
  color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;

// $dark-btn-hover-bg-shade-amount: 15% !default;
// $dark-btn-hover-bg-tint-amount: 15% !default;
// $dark-btn-hover-border-shade-amount: 20% !default;
// $dark-btn-hover-border-tint-amount: 10% !default;
// $dark-btn-active-bg-shade-amount: 20% !default;
// $dark-btn-active-bg-tint-amount: 20% !default;
// $dark-btn-active-border-shade-amount: 25% !default;
// $dark-btn-active-border-tint-amount: 10% !default;

// $dark-button-variants: (
//   "primary": btn-color-map($dark-primary, $dark-primary),
//   "secondary": btn-color-map($dark-secondary, $dark-secondary),
//   "success": btn-color-map($dark-success, $dark-success),
//   "danger": btn-color-map($dark-danger, $dark-danger),
//   "warning": btn-color-map($dark-warning, $dark-warning),
//   "info": btn-color-map($dark-info, $dark-info),
//   "light": btn-color-map($dark-light, $dark-light),
//   "dark": btn-color-map($dark-dark, $dark-dark),
// ) !default;

// $dark-button-outline-ghost-variants: (
//   "primary": btn-outline-color-map($dark-primary),
//   "secondary": btn-outline-color-map($dark-secondary),
//   "success": btn-outline-color-map($dark-success),
//   "danger": btn-outline-color-map($dark-danger),
//   "warning": btn-outline-color-map($dark-warning),
//   "info": btn-outline-color-map($dark-info),
//   "light": btn-outline-color-map($dark-light),
//   "dark": btn-outline-color-map($dark-dark),
// ) !default;
// // scss-docs-end btn-variables

// Forms
// scss-docs-start form-variables
// scss-docs-start form-text-variables
$dark-form-text-margin-top: 0.25rem !default;
$dark-form-text-font-size: $dark-small-font-size !default;
$dark-form-text-font-style: null !default;
$dark-form-text-font-weight: null !default;
$dark-form-text-color: $dark-text-muted !default;
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$dark-form-label-margin-bottom: 0.5rem !default;
$dark-form-label-font-size: null !default;
$dark-form-label-font-style: null !default;
$dark-form-label-font-weight: null !default;
$dark-form-label-color: null !default;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$dark-input-padding-y: $dark-input-btn-padding-y !default;
$dark-input-padding-x: $dark-input-btn-padding-x !default;
$dark-input-font-family: $dark-input-btn-font-family !default;
$dark-input-font-size: $dark-input-btn-font-size !default;
$dark-input-font-weight: $dark-font-weight-base !default;
$dark-input-line-height: $dark-input-btn-line-height !default;

$dark-input-padding-y-sm: $dark-input-btn-padding-y-sm !default;
$dark-input-padding-x-sm: $dark-input-btn-padding-x-sm !default;
$dark-input-font-size-sm: $dark-input-btn-font-size-sm !default;

$dark-input-padding-y-lg: $dark-input-btn-padding-y-lg !default;
$dark-input-padding-x-lg: $dark-input-btn-padding-x-lg !default;
$dark-input-font-size-lg: $dark-input-btn-font-size-lg !default;

$dark-input-bg: $dark-white !default;
$dark-input-disabled-bg: $dark-gray-200 !default;
$dark-input-disabled-border-color: $dark-gray-400 !default;

$dark-input-color: $dark-body-color !default;
$dark-input-border-color: $dark-gray-400 !default;
$dark-input-border-width: $dark-input-btn-border-width !default;
$dark-input-box-shadow: $dark-box-shadow-inset !default;

$dark-input-border-radius: $dark-border-radius !default;
$dark-input-border-radius-sm: $dark-border-radius-sm !default;
$dark-input-border-radius-lg: $dark-border-radius-lg !default;

$dark-input-focus-bg: $dark-input-bg !default;
$dark-input-focus-border-color: tint-color(
  $dark-component-active-bg,
  50%
) !default;
$dark-input-focus-color: $dark-input-color !default;
$dark-input-focus-width: $dark-input-btn-focus-width !default;
$dark-input-focus-box-shadow: $dark-input-btn-focus-box-shadow !default;

$dark-input-placeholder-color: $dark-gray-600 !default;
$dark-input-plaintext-color: $dark-body-color !default;

$dark-input-height-border: $dark-input-border-width * 2 !default;

$dark-input-height-inner: add(
  $dark-input-line-height * 1em,
  $dark-input-padding-y * 2
) !default;
$dark-input-height-inner-half: add(
  $dark-input-line-height * 0.5em,
  $dark-input-padding-y
) !default;
$dark-input-height-inner-quarter: add(
  $dark-input-line-height * 0.25em,
  calc($dark-input-padding-y / 2)
) !default;

$dark-input-height: add(
  $dark-input-line-height * 1em,
  add($dark-input-padding-y * 2, $dark-input-height-border, false)
) !default;
$dark-input-height-sm: add(
  $dark-input-line-height * 1em,
  add($dark-input-padding-y-sm * 2, $dark-input-height-border, false)
) !default;
$dark-input-height-lg: add(
  $dark-input-line-height * 1em,
  add($dark-input-padding-y-lg * 2, $dark-input-height-border, false)
) !default;

$dark-input-transition:
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;
// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
$dark-form-check-input-width: 1em !default;
$dark-form-check-min-height: $dark-font-size-base * $dark-line-height-base !default;
$dark-form-check-padding-start: $dark-form-check-input-width + 0.5em !default;
$dark-form-check-margin-bottom: 0.125rem !default;
$dark-form-check-label-color: unset !default;
$dark-form-check-label-cursor: null !default;
$dark-form-check-transition: null !default;

$dark-form-check-input-active-filter: brightness(90%) !default;

$dark-form-check-input-bg: $dark-input-bg !default;
$dark-form-check-input-border: 1px solid rgba($dark-black, 0.25) !default;
$dark-form-check-input-border-radius: 0.25em !default;
$dark-form-check-radio-border-radius: 50% !default;
$dark-form-check-input-focus-border: $dark-input-focus-border-color !default;
$dark-form-check-input-focus-box-shadow: $dark-input-btn-focus-box-shadow !default;

$dark-form-check-input-checked-color: $dark-component-active-color !default;
$dark-form-check-input-checked-bg-color: $dark-component-active-bg !default;
$dark-form-check-input-checked-border-color: $dark-form-check-input-checked-bg-color !default;
$dark-form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$dark-form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>") !default;
$dark-form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$dark-form-check-input-checked-color}'/></svg>") !default;

$dark-form-check-input-indeterminate-color: $dark-component-active-color !default;
$dark-form-check-input-indeterminate-bg-color: $dark-component-active-bg !default;
$dark-form-check-input-indeterminate-border-color: $dark-form-check-input-indeterminate-bg-color !default;
$dark-form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$dark-form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;

$dark-form-check-input-disabled-opacity: 0.5 !default;
$dark-form-check-label-disabled-opacity: $dark-form-check-input-disabled-opacity !default;
$dark-form-check-btn-check-disabled-opacity: $dark-btn-disabled-opacity !default;

$dark-form-check-inline-margin-end: 1rem !default;
// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
$dark-form-switch-color: rgba(0, 0, 0, 0.25) !default;
$dark-form-switch-width: 1.5em !default;
$dark-form-switch-padding-start: $dark-form-switch-width + 0.5em !default;
$dark-form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$dark-form-switch-color}'/></svg>") !default;
$dark-form-switch-border-radius: $dark-form-switch-width !default;
$dark-form-switch-transition: background-position 0.15s ease-in-out !default;

$dark-form-switch-focus-color: $dark-input-focus-border-color !default;
$dark-form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$dark-form-switch-focus-color}'/></svg>") !default;

$dark-form-switch-checked-color: $dark-component-active-color !default;
$dark-form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$dark-form-switch-checked-color}'/></svg>") !default;
$dark-form-switch-checked-bg-position: right center !default;

$dark-form-switch-widths: (
  lg: (
    width: 1.75em,
    height: 1.25em,
  ),
  xl: (
    width: 2em,
    height: 1.5em,
  ),
) !default;
// scss-docs-end form-switch-variables

$dark-form-check-inline-margin-end: 1rem !default;

// scss-docs-start input-group-variables
$dark-input-group-addon-padding-y: $dark-input-padding-y !default;
$dark-input-group-addon-padding-x: $dark-input-padding-x !default;
$dark-input-group-addon-font-weight: $dark-input-font-weight !default;
$dark-input-group-addon-color: $dark-input-color !default;
$dark-input-group-addon-bg: $dark-gray-200 !default;
$dark-input-group-addon-border-color: $dark-input-border-color !default;
// scss-docs-end input-group-variables

// scss-docs-start form-select-variables
$dark-form-select-padding-y: $dark-input-padding-y !default;
$dark-form-select-padding-x: $dark-input-padding-x !default;
$dark-form-select-font-family: $dark-input-font-family !default;
$dark-form-select-font-size: $dark-input-font-size !default;
$dark-form-select-indicator-padding: $dark-form-select-padding-x * 3 !default; // Extra padding for background-image
$dark-form-select-font-weight: $dark-input-font-weight !default;
$dark-form-select-line-height: $dark-input-line-height !default;
$dark-form-select-color: $dark-input-color !default;
$dark-form-select-bg: $dark-input-bg !default;
$dark-form-select-disabled-color: null !default;
$dark-form-select-disabled-bg: $dark-gray-200 !default;
$dark-form-select-disabled-border-color: $dark-input-disabled-border-color !default;
$dark-form-select-bg-position: right $dark-form-select-padding-x center !default;
$dark-form-select-bg-size: 16px 12px !default; // In pixels because image dimensions
$dark-form-select-indicator-color: $dark-gray-800 !default;
$dark-form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$dark-form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>") !default;

$dark-form-select-feedback-icon-padding-end: $dark-form-select-padding-x * 2.5 +
  $dark-form-select-indicator-padding !default;
$dark-form-select-feedback-icon-position: center right
  $dark-form-select-indicator-padding !default;
$dark-form-select-feedback-icon-size: $dark-input-height-inner-half
  $dark-input-height-inner-half !default;

$dark-form-select-border-width: $dark-input-border-width !default;
$dark-form-select-border-color: $dark-input-border-color !default;
$dark-form-select-border-radius: $dark-border-radius !default;
$dark-form-select-box-shadow: $dark-box-shadow-inset !default;

$dark-form-select-focus-border-color: $dark-input-focus-border-color !default;
$dark-form-select-focus-width: $dark-input-focus-width !default;
$dark-form-select-focus-box-shadow: 0 0 0 $dark-form-select-focus-width
  $dark-input-btn-focus-color !default;

$dark-form-select-padding-y-sm: $dark-input-padding-y-sm !default;
$dark-form-select-padding-x-sm: $dark-input-padding-x-sm !default;
$dark-form-select-font-size-sm: $dark-input-font-size-sm !default;

$dark-form-select-padding-y-lg: $dark-input-padding-y-lg !default;
$dark-form-select-padding-x-lg: $dark-input-padding-x-lg !default;
$dark-form-select-font-size-lg: $dark-input-font-size-lg !default;
// scss-docs-end form-select-variables

// scss-docs-start form-range-variables
$dark-form-range-track-width: 100% !default;
$dark-form-range-track-height: 0.5rem !default;
$dark-form-range-track-cursor: pointer !default;
$dark-form-range-track-bg: $dark-gray-300 !default;
$dark-form-range-track-border-radius: 1rem !default;
$dark-form-range-track-box-shadow: $dark-box-shadow-inset !default;

$dark-form-range-thumb-width: 1rem !default;
$dark-form-range-thumb-height: $dark-form-range-thumb-width !default;
$dark-form-range-thumb-bg: $dark-component-active-bg !default;
$dark-form-range-thumb-border: 0 !default;
$dark-form-range-thumb-border-radius: 1rem !default;
$dark-form-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($dark-black, 0.1) !default;
$dark-form-range-thumb-focus-box-shadow:
  0 0 0 1px $dark-body-bg,
  $dark-input-focus-box-shadow !default;
$dark-form-range-thumb-focus-box-shadow-width: $dark-input-focus-width !default; // For focus box shadow issue in Edge
$dark-form-range-thumb-active-bg: tint-color(
  $dark-component-active-bg,
  70%
) !default;
$dark-form-range-thumb-disabled-bg: $dark-gray-500 !default;
$dark-form-range-thumb-transition:
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;
// scss-docs-end form-range-variables

// scss-docs-start form-file-variables
$dark-form-file-button-color: $dark-input-color !default;
$dark-form-file-button-bg: $dark-input-group-addon-bg !default;
$dark-form-file-button-hover-bg: shade-color(
  $dark-form-file-button-bg,
  5%
) !default;
// scss-docs-end form-file-variables

// scss-docs-start form-floating-variables
$dark-form-floating-height: add(3.5rem, $dark-input-height-border) !default;
$dark-form-floating-padding-x: $dark-input-padding-x !default;
$dark-form-floating-padding-y: 1rem !default;
$dark-form-floating-input-padding-t: 1.625rem !default;
$dark-form-floating-input-padding-b: 0.625rem !default;
$dark-form-floating-label-opacity: 0.65 !default;
$dark-form-floating-label-transform: scale(0.85) translateY(-0.5rem)
  translateX(0.15rem) !default;
$dark-form-floating-transition:
  opacity 0.1s ease-in-out,
  transform 0.1s ease-in-out !default;
// scss-docs-end form-floating-variables

// Form validation

// scss-docs-start form-feedback-variables
$dark-form-feedback-margin-top: $dark-form-text-margin-top !default;
$dark-form-feedback-font-size: $dark-form-text-font-size !default;
$dark-form-feedback-font-style: $dark-form-text-font-style !default;
$dark-form-feedback-valid-color: $dark-success !default;
$dark-form-feedback-invalid-color: $dark-danger !default;

$dark-form-feedback-icon-valid-color: $dark-form-feedback-valid-color !default;
$dark-form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$dark-form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$dark-form-feedback-icon-invalid-color: $dark-form-feedback-invalid-color !default;
$dark-form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$dark-form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$dark-form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;
// scss-docs-end form-feedback-variables

// scss-docs-start form-validation-states
$dark-form-validation-states: (
  'valid': (
    'color': $dark-form-feedback-valid-color,
    'icon': $dark-form-feedback-icon-valid,
  ),
  'invalid': (
    'color': $dark-form-feedback-invalid-color,
    'icon': $dark-form-feedback-icon-invalid,
  ),
) !default;
// scss-docs-end form-validation-states
// scss-docs-end form-variables
// Z-index master list

// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$dark-zindex-dropdown: 1000 !default;
$dark-zindex-sticky: 1020 !default;
$dark-zindex-fixed: 1030 !default;
$dark-zindex-modal-backdrop: 1040 !default;
$dark-zindex-offcanvas: 1050 !default;
$dark-zindex-modal: 1060 !default;
$dark-zindex-popover: 1070 !default;
$dark-zindex-tooltip: 1080 !default;
$dark-zindex-toaster: 1090 !default;
// scss-docs-end zindex-stack

// Navs
// scss-docs-start nav-variables
$dark-nav-link-padding-y: 0.5rem !default;
$dark-nav-link-padding-x: 1rem !default;
$dark-nav-link-font-size: null !default;
$dark-nav-link-font-weight: null !default;
$dark-nav-link-color: $dark-link-color !default;
$dark-nav-link-hover-color: $dark-link-hover-color !default;
$dark-nav-link-transition:
  color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out !default;
$dark-nav-link-disabled-color: $dark-gray-600 !default;

$dark-nav-tabs-border-color: $dark-gray-300 !default;
$dark-nav-tabs-border-width: $dark-border-width !default;
$dark-nav-tabs-border-radius: $dark-border-radius !default;
$dark-nav-tabs-link-hover-border-color: $dark-gray-200 $dark-gray-200
  $dark-nav-tabs-border-color !default;
$dark-nav-tabs-link-active-color: $dark-gray-700 !default;
$dark-nav-tabs-link-active-bg: $dark-body-bg !default;
$dark-nav-tabs-link-active-border-color: $dark-gray-300 $dark-gray-300
  $dark-nav-tabs-link-active-bg !default;

$dark-nav-pills-border-radius: $dark-border-radius !default;
$dark-nav-pills-link-active-color: $dark-component-active-color !default;
$dark-nav-pills-link-active-bg: $dark-component-active-bg !default;
// scss-docs-end nav-variables

// Navbar

// scss-docs-start navbar-variables
$dark-navbar-padding-y: calc($dark-spacer / 2) !default;
$dark-navbar-padding-x: null !default;

$dark-navbar-nav-link-padding-x: 0.5rem !default;

$dark-navbar-brand-font-size: $dark-font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$dark-nav-link-height: $dark-font-size-base * $dark-line-height-base +
  $dark-nav-link-padding-y * 2 !default;
$dark-navbar-brand-height: $dark-navbar-brand-font-size * $dark-line-height-base !default;
$dark-navbar-brand-padding-y: calc(
  ($dark-nav-link-height - $dark-navbar-brand-height) / 2
) !default;
$dark-navbar-brand-margin-end: 1rem !default;

$dark-navbar-toggler-padding-y: 0.25rem !default;
$dark-navbar-toggler-padding-x: 0.75rem !default;
$dark-navbar-toggler-font-size: $dark-font-size-lg !default;
$dark-navbar-toggler-border-radius: $dark-btn-border-radius !default;
$dark-navbar-toggler-focus-width: $dark-btn-focus-width !default;
$dark-navbar-toggler-transition: box-shadow 0.15s ease-in-out !default;
// scss-docs-end navbar-variables

// scss-docs-start navbar-theme-variables
$dark-navbar-dark-color: $dark-medium-emphasis-inverse !default;
$dark-navbar-dark-hover-color: $dark-high-emphasis-inverse !default;
$dark-navbar-dark-active-color: $dark-high-emphasis-inverse !default;
$dark-navbar-dark-disabled-color: $dark-disabled-inverse !default;
$dark-navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$dark-navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$dark-navbar-dark-toggler-border-color: rgba($dark-white, 0.1) !default;

$dark-navbar-light-color: $dark-medium-emphasis !default;
$dark-navbar-light-hover-color: $dark-high-emphasis !default;
$dark-navbar-light-active-color: $dark-high-emphasis !default;
$dark-navbar-light-disabled-color: $dark-disabled !default;
$dark-navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$dark-navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$dark-navbar-light-toggler-border-color: rgba($dark-black, 0.1) !default;

$dark-navbar-light-brand-color: $dark-navbar-light-active-color !default;
$dark-navbar-light-brand-hover-color: $dark-navbar-light-active-color !default;
$dark-navbar-dark-brand-color: $dark-navbar-dark-active-color !default;
$dark-navbar-dark-brand-hover-color: $dark-navbar-dark-active-color !default;
// scss-docs-end navbar-theme-variables

// Dropdowns

// Dropdown menu container and contents.
// scss-docs-start dropdown-variables
$dark-dropdown-min-width: 10rem !default;
$dark-dropdown-padding-x: 0 !default;
$dark-dropdown-padding-y: 0.5rem !default;
$dark-dropdown-spacer: 0.125rem !default;
$dark-dropdown-font-size: $dark-font-size-base !default;
$dark-dropdown-color: $dark-body-color !default;
$dark-dropdown-bg: $dark-white !default;
$dark-dropdown-border-color: rgba($dark-black, 0.15) !default;
$dark-dropdown-border-radius: $dark-border-radius !default;
$dark-dropdown-border-width: $dark-border-width !default;
$dark-dropdown-inner-border-radius: subtract(
  $dark-dropdown-border-radius,
  $dark-dropdown-border-width
) !default;
$dark-dropdown-divider-bg: $dark-dropdown-border-color !default;
$dark-dropdown-divider-margin-y: calc($dark-spacer / 2) !default;
$dark-dropdown-box-shadow: $dark-box-shadow !default;

$dark-dropdown-link-color: $dark-gray-900 !default;
$dark-dropdown-link-hover-color: shade-color($dark-gray-900, 10%) !default;
$dark-dropdown-link-hover-bg: #2c2f31;

$dark-dropdown-link-active-color: $dark-component-active-color !default;
$dark-dropdown-link-active-bg: $dark-component-active-bg !default;

$dark-dropdown-link-disabled-color: $dark-gray-500 !default;

$dark-dropdown-item-padding-y: calc($dark-spacer / 4) !default;
$dark-dropdown-item-padding-x: $dark-spacer !default;

$dark-dropdown-header-color: $dark-gray-600 !default;
$dark-dropdown-header-padding: $dark-dropdown-padding-y
  $dark-dropdown-item-padding-x !default;
// scss-docs-end dropdown-variables

// scss-docs-start dropdown-dark-variables
$dark-dropdown-dark-color: $dark-gray-300 !default;
$dark-dropdown-dark-bg: $dark-gray-800 !default;
$dark-dropdown-dark-border-color: $dark-dropdown-border-color !default;
$dark-dropdown-dark-divider-bg: $dark-dropdown-divider-bg !default;
$dark-dropdown-dark-box-shadow: null !default;
$dark-dropdown-dark-link-color: $dark-dropdown-dark-color !default;
$dark-dropdown-dark-link-hover-color: $dark-high-emphasis-inverse !default;
$dark-dropdown-dark-link-hover-bg: rgba($dark-white, 0.15) !default;
$dark-dropdown-dark-link-active-color: $dark-dropdown-link-active-color !default;
$dark-dropdown-dark-link-active-bg: $dark-dropdown-link-active-bg !default;
$dark-dropdown-dark-link-disabled-color: $dark-gray-500 !default;
$dark-dropdown-dark-header-color: $dark-gray-500 !default;
// scss-docs-end dropdown-dark-variables

// Pagination

// scss-docs-start pagination-variables
$dark-pagination-padding-y: 0.375rem !default;
$dark-pagination-padding-x: 0.75rem !default;
$dark-pagination-padding-y-sm: 0.25rem !default;
$dark-pagination-padding-x-sm: 0.5rem !default;
$dark-pagination-padding-y-lg: 0.75rem !default;
$dark-pagination-padding-x-lg: 1.5rem !default;

$dark-pagination-color: $dark-link-color !default;
$dark-pagination-bg: $dark-white !default;
$dark-pagination-border-width: $dark-border-width !default;
$dark-pagination-border-radius: $dark-border-radius !default;
$dark-pagination-margin-start: -$dark-pagination-border-width !default;
$dark-pagination-border-color: $dark-gray-300 !default;

$dark-pagination-focus-color: $dark-link-hover-color !default;
$dark-pagination-focus-bg: $dark-gray-200 !default;
$dark-pagination-focus-box-shadow: $dark-input-btn-focus-box-shadow !default;
$dark-pagination-focus-outline: 0 !default;

$dark-pagination-hover-color: $dark-link-hover-color !default;
$dark-pagination-hover-bg: $dark-gray-200 !default;
$dark-pagination-hover-border-color: $dark-gray-300 !default;

$dark-pagination-active-color: $dark-component-active-color !default;
$dark-pagination-active-bg: $dark-component-active-bg !default;
$dark-pagination-active-border-color: $dark-pagination-active-bg !default;

$dark-pagination-disabled-color: $dark-gray-600 !default;
$dark-pagination-disabled-bg: $dark-white !default;
$dark-pagination-disabled-border-color: $dark-gray-300 !default;

$dark-pagination-transition:
  color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;
// scss-docs-end pagination-variables

$dark-pagination-border-radius-sm: $dark-border-radius-sm !default;
$dark-pagination-border-radius-lg: $dark-border-radius-lg !default;
// scss-docs-end pagination-variables

// Cards
// scss-docs-start card-variables
$dark-card-spacer-y: $dark-spacer !default;
$dark-card-spacer-x: $dark-spacer !default;
$dark-card-title-spacer-y: calc($dark-spacer / 2) !default;
$dark-card-border-width: $dark-border-width !default;
$dark-card-border-radius: $dark-border-radius !default;
$dark-card-border-color: rgba($dark-black, 0.125) !default;
$dark-card-inner-border-radius: subtract(
  $dark-card-border-radius,
  $dark-card-border-width
) !default;
$dark-card-cap-padding-y: calc($dark-card-spacer-y / 2) !default;
$dark-card-cap-padding-x: $dark-card-spacer-x !default;
$dark-card-cap-bg: rgba($dark-black, 0.03) !default;
$dark-card-cap-color: unset !default;
$dark-card-height: null !default;
$dark-card-color: unset !default;
$dark-card-bg: $dark-white !default;
$dark-card-img-overlay-padding: $dark-spacer !default;
$dark-card-group-margin: calc($dark-grid-gutter-width / 2) !default;
// scss-docs-end card-variables

// Accordion
// scss-docs-start accordion-variables
$dark-accordion-padding-y: 1rem !default;
$dark-accordion-padding-x: 1.25rem !default;
$dark-accordion-color: $dark-body-color !default;
$dark-accordion-bg: $dark-body-bg !default;
$dark-accordion-border-width: $dark-border-width !default;
$dark-accordion-border-color: rgba($dark-black, 0.125) !default;
$dark-accordion-border-radius: $dark-border-radius !default;
$dark-accordion-inner-border-radius: subtract(
  $dark-accordion-border-radius,
  $dark-accordion-border-width
) !default;

$dark-accordion-body-padding-y: $dark-accordion-padding-y !default;
$dark-accordion-body-padding-x: $dark-accordion-padding-x !default;

$dark-accordion-button-padding-y: $dark-accordion-padding-y !default;
$dark-accordion-button-padding-x: $dark-accordion-padding-x !default;
$dark-accordion-button-color: $dark-accordion-color !default;
$dark-accordion-button-bg: $dark-accordion-bg !default;
$dark-accordion-transition:
  $dark-btn-transition,
  border-radius 0.15s ease !default;
$dark-accordion-button-active-bg: tint-color(
  $dark-component-active-bg,
  90%
) !default;
$dark-accordion-button-active-color: shade-color($dark-primary, 10%) !default;

$dark-accordion-button-focus-border-color: $dark-input-focus-border-color !default;
$dark-accordion-button-focus-box-shadow: $dark-btn-focus-box-shadow !default;

$dark-accordion-icon-width: 1.25rem !default;
$dark-accordion-icon-color: $dark-accordion-color !default;
$dark-accordion-icon-active-color: $dark-accordion-button-active-color !default;
$dark-accordion-icon-transition: transform 0.2s ease-in-out !default;
$dark-accordion-icon-transform: rotate(-180deg) !default;

$dark-accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$dark-accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$dark-accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$dark-accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
// scss-docs-end accordion-variables

// Tooltips

// scss-docs-start tooltip-variables
$dark-tooltip-font-size: $dark-font-size-sm !default;
$dark-tooltip-max-width: 200px !default;
$dark-tooltip-color: $dark-high-emphasis-inverse !default;
$dark-tooltip-bg: $dark-black !default;
$dark-tooltip-border-radius: $dark-border-radius !default;
$dark-tooltip-opacity: 0.9 !default;
$dark-tooltip-padding-y: calc($dark-spacer / 4) !default;
$dark-tooltip-padding-x: calc($dark-spacer / 2) !default;
$dark-tooltip-margin: 0 !default;

$dark-tooltip-arrow-width: 0.8rem !default;
$dark-tooltip-arrow-height: 0.4rem !default;
$dark-tooltip-arrow-color: $dark-tooltip-bg !default;
// scss-docs-end tooltip-variables

// Form tooltips must come after regular tooltips
// scss-docs-start tooltip-feedback-variables
$dark-form-feedback-tooltip-padding-y: $dark-tooltip-padding-y !default;
$dark-form-feedback-tooltip-padding-x: $dark-tooltip-padding-x !default;
$dark-form-feedback-tooltip-font-size: $dark-tooltip-font-size !default;
$dark-form-feedback-tooltip-line-height: null !default;
$dark-form-feedback-tooltip-opacity: $dark-tooltip-opacity !default;
$dark-form-feedback-tooltip-border-radius: $dark-tooltip-border-radius !default;
// scss-docs-end tooltip-feedback-variables

// Popovers
// scss-docs-start popover-variables
$dark-popover-font-size: $dark-font-size-sm !default;
$dark-popover-bg: $dark-white !default;
$dark-popover-max-width: 276px !default;
$dark-popover-border-width: $dark-border-width !default;
$dark-popover-border-color: rgba($dark-black, 0.2) !default;
$dark-popover-border-radius: $dark-border-radius-lg !default;
$dark-popover-inner-border-radius: subtract(
  $dark-popover-border-radius,
  $dark-popover-border-width
) !default;
$dark-popover-box-shadow: $dark-box-shadow !default;

$dark-popover-header-bg: shade-color($dark-popover-bg, 6%) !default;
$dark-popover-header-color: $dark-headings-color !default;
$dark-popover-header-padding-y: 0.5rem !default;
$dark-popover-header-padding-x: $dark-spacer !default;

$dark-popover-body-color: $dark-body-color !default;
$dark-popover-body-padding-y: $dark-spacer !default;
$dark-popover-body-padding-x: $dark-spacer !default;

$dark-popover-arrow-width: 1rem !default;
$dark-popover-arrow-height: 0.5rem !default;
$dark-popover-arrow-color: $dark-popover-bg !default;

$dark-popover-arrow-outer-color: fade-in(
  $dark-popover-border-color,
  0.05
) !default;
// scss-docs-end popover-variables

// Toasts
// scss-docs-start toast-variables
$dark-toast-max-width: 350px !default;
$dark-toast-padding-x: 0.75rem !default;
$dark-toast-padding-y: 0.5rem !default;
$dark-toast-font-size: 0.875rem !default;
$dark-toast-color: unset !default;
$dark-toast-background-color: rgba($dark-white, 0.85) !default;
$dark-toast-border-width: 1px !default;
$dark-toast-border-color: rgba(0, 0, 0, 0.1) !default;
$dark-toast-border-radius: $dark-border-radius !default;
$dark-toast-box-shadow: $dark-box-shadow !default;
$dark-toast-spacing: $dark-container-padding-x !default;

$dark-toast-header-color: $dark-gray-600 !default;
$dark-toast-header-background-color: rgba($dark-white, 0.85) !default;
$dark-toast-header-border-color: rgba(0, 0, 0, 0.05) !default;
// scss-docs-end toast-variables

// Badges
// scss-docs-start badge-variables
$dark-badge-font-size: 0.75em !default;
$dark-badge-font-weight: $dark-font-weight-bold !default;
$dark-badge-color: $dark-high-emphasis-inverse !default;
$dark-badge-padding-y: 0.35em !default;
$dark-badge-padding-x: 0.65em !default;
$dark-badge-border-radius: $dark-border-radius !default;
// scss-docs-end badge-variables

$dark-badge-font-size-sm: 0.65em !default;
$dark-badge-padding-y-sm: 0.3em !default;
$dark-badge-padding-x-sm: 0.5em !default;
// scss-docs-end badge-variables

// Modals

// scss-docs-start modal-variables
$dark-modal-inner-padding: $dark-spacer !default;

$dark-modal-footer-margin-between: 0.5rem !default;

$dark-modal-dialog-margin: 0.5rem !default;
$dark-modal-dialog-margin-y-sm-up: 1.75rem !default;

$dark-modal-title-line-height: $dark-line-height-base !default;

$dark-modal-content-color: unset !default;
$dark-modal-content-bg: $dark-white !default;
$dark-modal-content-border-color: rgba($dark-black, 0.2) !default;
$dark-modal-content-border-width: $dark-border-width !default;
$dark-modal-content-border-radius: $dark-border-radius-lg !default;
$dark-modal-content-inner-border-radius: subtract(
  $dark-modal-content-border-radius,
  $dark-modal-content-border-width
) !default;
$dark-modal-content-box-shadow-xs: $dark-box-shadow-sm !default;
$dark-modal-content-box-shadow-sm-up: $dark-box-shadow !default;

$dark-modal-backdrop-bg: $dark-black !default;
$dark-modal-backdrop-opacity: 0.5 !default;
$dark-modal-header-border-color: $dark-border-color !default;
$dark-modal-footer-border-color: $dark-modal-header-border-color !default;
$dark-modal-header-border-width: $dark-modal-content-border-width !default;
$dark-modal-footer-border-width: $dark-modal-header-border-width !default;
$dark-modal-header-padding-y: $dark-modal-inner-padding !default;
$dark-modal-header-padding-x: $dark-modal-inner-padding !default;
$dark-modal-header-padding: $dark-modal-header-padding-y
  $dark-modal-header-padding-x !default; // Keep this for backwards compatibility

$dark-modal-sm: 300px !default;
$dark-modal-md: 500px !default;
$dark-modal-lg: 800px !default;
$dark-modal-xl: 1140px !default;

$dark-modal-fade-transform: translate(0, -50px) !default;
$dark-modal-show-transform: none !default;
$dark-modal-transition: transform 0.3s ease-out !default;
$dark-modal-scale-transform: scale(1.02) !default;
// scss-docs-end modal-variables

// Avatars
// scss-docs-start avatar-variables
$dark-avatar-width: 2rem !default;

$dark-avatar-widths: (
  sm: 1.5rem,
  md: 2.5rem,
  lg: 3rem,
  xl: 4rem,
) !default;

$dark-avatar-transition: margin 0.15s !default;
// scss-docs-end avatar-variables

// Alerts

// Define alert colors, border radius, and padding.

// scss-docs-start alert-variables
$dark-alert-padding-y: $dark-spacer !default;
$dark-alert-padding-x: $dark-spacer !default;
$dark-alert-margin-bottom: 1rem !default;
$dark-alert-border-radius: $dark-border-radius !default;
$dark-alert-link-font-weight: $dark-font-weight-bold !default;
$dark-alert-border-width: $dark-border-width !default;
$dark-alert-bg-scale: -80% !default;
$dark-alert-border-scale: -70% !default;
$dark-alert-color-scale: 40% !default;
$dark-alert-dismissible-padding-r: $dark-alert-padding-x * 3 !default; // 3x covers width of x plus default padding on either side
// scss-docs-end alert-variables

$dark-alert-variants: (
  'primary': alert-color-map($dark-primary),
  'secondary': alert-color-map($dark-secondary),
  'success': alert-color-map($dark-success),
  'danger': alert-color-map($dark-danger),
  'warning': alert-color-map($dark-warning),
  'info': alert-color-map($dark-info),
  'light': alert-color-map($dark-light),
  'dark': alert-color-map($dark-dark),
) !default;
// scss-docs-end alert-variables

// Callouts
// scss-docs-start callout-variables
$dark-callout-padding-y: $dark-spacer !default;
$dark-callout-padding-x: $dark-spacer !default;
$dark-callout-margin-y: $dark-spacer !default;
$dark-callout-margin-x: 0 !default;
$dark-callout-border-radius: $dark-border-radius !default;
$dark-callout-border-width: $dark-border-width !default;
$dark-callout-border-color: $dark-border-color !default;
$dark-callout-border-left-width: (4 * $dark-callout-border-width) !default;

$dark-callout-variants: (
  'primary': $dark-primary,
  'secondary': $dark-secondary,
  'success': $dark-success,
  'danger': $dark-danger,
  'warning': $dark-warning,
  'info': $dark-info,
  'light': $dark-light,
  'dark': $dark-dark,
) !default;
// scss-docs-end callout-variables

// Progress bars

// scss-docs-start progress-variables
$dark-progress-height: 1rem !default;
$dark-progress-font-size: $dark-font-size-base * 0.75 !default;
$dark-progress-bg: $dark-gray-200 !default;
$dark-progress-border-radius: $dark-border-radius !default;
$dark-progress-box-shadow: $dark-box-shadow-inset !default;
$dark-progress-bar-color: $dark-high-emphasis-inverse !default;
$dark-progress-bar-bg: $dark-primary !default;
$dark-progress-bar-animation-timing: 1s linear infinite !default;
$dark-progress-bar-transition: width 0.6s ease !default;
// scss-docs-end progress-variables

// List group
// scss-docs-start list-group-variables
// $dark-list-group-color: unset !default;
// $dark-list-group-bg: $dark-white !default;
// $dark-list-group-border-color: rgba($dark-black, 0.125) !default;
// $dark-list-group-border-width: $dark-border-width !default;
// $dark-list-group-border-radius: $dark-border-radius !default;

// $dark-list-group-item-padding-y: $dark-spacer / 2 !default;
// $dark-list-group-item-padding-x: $dark-spacer !default;
// $dark-list-group-item-bg-scale: -80% !default;
// $dark-list-group-item-color-scale: 40% !default;

// $dark-list-group-hover-bg: $dark-gray-100 !default;
// $dark-list-group-active-color: $dark-component-active-color !default;
// $dark-list-group-active-bg: $dark-component-active-bg !default;
// $dark-list-group-active-border-color: $dark-list-group-active-bg !default;

// $dark-list-group-disabled-color: $dark-gray-600 !default;
// $dark-list-group-disabled-bg: $dark-list-group-bg !default;

// $dark-list-group-action-color: $dark-gray-700 !default;
// $dark-list-group-action-hover-color: $dark-list-group-action-color !default;

// $dark-list-group-action-active-color: $dark-body-color !default;
// $dark-list-group-action-active-bg: $dark-gray-200 !default;
// // scss-docs-end list-group-variables

// $dark-list-group-variants: (
//   "primary": list-group-color-map($dark-primary),
//   "secondary": list-group-color-map($dark-secondary),
//   "success": list-group-color-map($dark-success),
//   "danger": list-group-color-map($dark-danger),
//   "warning": list-group-color-map($dark-warning),
//   "info": list-group-color-map($dark-info),
//   "light": list-group-color-map($dark-light),
//   "dark": list-group-color-map($dark-dark),
// ) !default;
// scss-docs-end list-group-variables

// Header
// scss-docs-start header-variables
$dark-header-min-height: 4rem !default;
$dark-header-padding-y: calc($dark-spacer / 2) !default;
$dark-header-padding-x: calc($dark-spacer / 2) !default;
$dark-header-brand-font-size: $dark-font-size-lg !default;
$dark-header-color: $dark-medium-emphasis !default;
$dark-header-bg: #181a1b !default;
$dark-header-border-color: $dark-border-color !default;
$dark-header-border-width: 1px !default;
$dark-header-hover-color: $dark-high-emphasis !default;
$dark-header-active-color: $dark-high-emphasis !default;
$dark-header-disabled-color: $dark-disabled !default;

// Compute the header-brand padding-y so the header-brand will have the same height as header-text and nav-link
$dark-nav-link-height: $dark-font-size-base * $dark-line-height-base +
  $dark-nav-link-padding-y * 2 !default;
$dark-header-brand-height: $dark-header-brand-font-size * $dark-line-height-base !default;
$dark-header-brand-padding-y: calc(
  ($dark-nav-link-height - $dark-header-brand-height) / 2
) !default;
$dark-header-brand-margin-end: 1rem !default;
$dark-header-brand-font-size: $dark-font-size-lg !default;
$dark-header-brand-color: $dark-gray-900 !default;
$dark-header-brand-hover-color: shade-color($dark-gray-900, 10%) !default;

$dark-header-toggler-padding-y: 0.25rem !default;
$dark-header-toggler-padding-x: 0.75rem !default;
$dark-header-toggler-font-size: $dark-font-size-lg !default;
$dark-header-toggler-bg: transparent !default;
$dark-header-toggler-color: rgba(179, 195, 210, 0.68);
$dark-header-toggler-border: 0 !default;
$dark-header-toggler-border-radius: $dark-btn-border-radius !default;

$dark-header-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$dark-header-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !default;
$dark-header-toggler-hover-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$dark-header-hover-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !default;
$dark-header-toggler-border-color: rgba($dark-black, 0.1) !default;

$dark-header-nav-link-padding-x: 0.5rem !default;

$dark-header-divider-border-width: 1px !default;
$dark-header-divider-border-color: $dark-header-border-color !default;
// scss-docs-end header-variables

// Subheader
// scss-docs-start subheader-variables
$dark-subheader-min-height: 3rem !default;
$dark-subheader-padding-y: calc($dark-spacer / 2) !default;
$dark-subheader-padding-x: $dark-spacer !default;
$dark-subheader-border-color: $dark-border-color !default;
$dark-subheader-border-width: 1px !default;

$dark-subheader-nav-link-padding-x: 0.5rem !default;
// scss-docs-end subheader-variables

// Default theme
// scss-docs-start subheader-default-themes
$dark-subheader-bg: $dark-white !default;
$dark-subheader-color: $dark-medium-emphasis !default;
$dark-subheader-hover-color: $dark-high-emphasis !default;
$dark-subheader-active-color: $dark-high-emphasis !default;
$dark-subheader-disabled-color: $dark-disabled !default;
// scss-docs-end subheader-default-themes

// Image thumbnails

// scss-docs-start thumbnail-variables
$dark-thumbnail-padding: 0.25rem !default;
$dark-thumbnail-bg: $dark-body-bg !default;
$dark-thumbnail-border-width: $dark-border-width !default;
$dark-thumbnail-border-color: $dark-gray-300 !default;
$dark-thumbnail-border-radius: $dark-border-radius !default;
$dark-thumbnail-box-shadow: $dark-box-shadow-sm !default;
// scss-docs-end thumbnail-variables

// Figures

// scss-docs-start figure-variables
$dark-figure-caption-font-size: $dark-small-font-size !default;
$dark-figure-caption-color: $dark-gray-600 !default;
// scss-docs-end figure-variables

// Breadcrumbs
// scss-docs-start breadcrumb-variables
$dark-breadcrumb-font-size: null !default;
$dark-breadcrumb-padding-y: 0 !default;
$dark-breadcrumb-padding-x: 0 !default;
$dark-breadcrumb-item-padding-x: 0.5rem !default;
$dark-breadcrumb-margin-bottom: 1rem !default;
$dark-breadcrumb-bg: unset !default;
$dark-breadcrumb-divider-color: $dark-gray-600 !default;
$dark-breadcrumb-active-color: $dark-gray-600 !default;
$dark-breadcrumb-divider: quote('/') !default;
$dark-breadcrumb-divider-flipped: $dark-breadcrumb-divider !default;
$dark-breadcrumb-border-radius: null !default;
// scss-docs-end breadcrumb-variables

// Carousel
// scss-docs-start carousel-variables
$dark-carousel-control-color: $dark-high-emphasis-inverse !default;
$dark-carousel-control-width: 15% !default;
$dark-carousel-control-opacity: 0.5 !default;
$dark-carousel-control-hover-opacity: 0.9 !default;
$dark-carousel-control-transition: opacity 0.15s ease !default;

$dark-carousel-indicator-width: 30px !default;
$dark-carousel-indicator-height: 3px !default;
$dark-carousel-indicator-hit-area-height: 10px !default;
$dark-carousel-indicator-spacer: 3px !default;
$dark-carousel-indicator-opacity: 0.5 !default;
$dark-carousel-indicator-active-bg: $dark-white !default;
$dark-carousel-indicator-active-opacity: 1 !default;
$dark-carousel-indicator-transition: opacity 0.6s ease !default;

$dark-carousel-caption-width: 70% !default;
$dark-carousel-caption-color: $dark-high-emphasis-inverse !default;
$dark-carousel-caption-padding-y: 1.25rem !default;
$dark-carousel-caption-spacer: 1.25rem !default;

$dark-carousel-control-icon-width: 2rem !default;

$dark-carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$dark-carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$dark-carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$dark-carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;

$dark-carousel-transition-duration: 0.6s !default;
$dark-carousel-transition: transform $dark-carousel-transition-duration
  ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

$dark-carousel-dark-indicator-active-bg: $dark-black !default;
$dark-carousel-dark-caption-color: $dark-high-emphasis !default;
$dark-carousel-dark-control-icon-filter: invert(1) grayscale(100) !default;
// scss-docs-end carousel-variables

// scss-docs-start sidebar-variables
$dark-sidebar-width: 16rem !default;
$dark-sidebar-widths: (
  sm: 12rem,
  lg: 20rem,
  xl: 24rem,
) !default;
$dark-sidebar-padding-y: 0 !default;
$dark-sidebar-padding-x: 0 !default;
$dark-sidebar-color: $dark-high-emphasis-inverse !default;
// $dark-sidebar-bg: $dark-gray-base !default;
$dark-sidebar-bg: #010024;
$dark-sidebar-border-width: 0 !default;
$dark-sidebar-border-color: transparent !default;
$dark-sidebar-transition:
  margin-left 0.15s,
  margin-right 0.15s,
  box-shadow 0.075s,
  transform 0.15s,
  width 0.15s,
  z-index 0s ease 0.15s !default;

$dark-sidebar-brand-height: 4rem !default;
$dark-sidebar-brand-color: $dark-high-emphasis-inverse !default;
$dark-sidebar-brand-bg: #010024;

$dark-sidebar-header-height: 4rem !default;
$dark-sidebar-header-padding-y: 0.75rem !default;
$dark-sidebar-header-padding-x: 1rem !default;
$dark-sidebar-header-bg: rgba($dark-black, 0.2) !default;
$dark-sidebar-header-height-transition:
  height 0.15s,
  padding 0.15s !default;

$dark-sidebar-narrow-width: 4rem !default;

$dark-sidebar-backdrop-bg: $dark-black !default;
$dark-sidebar-backdrop-opacity: 0.5 !default;
$dark-sidebar-backdrop-transition: opacity 0.15s linear !default;

$dark-sidebar-nav-title-padding-y: 0.75rem !default;
$dark-sidebar-nav-title-padding-x: 1rem !default;
$dark-sidebar-nav-title-margin-top: 1rem !default;
$dark-sidebar-nav-title-color: $dark-medium-emphasis-inverse !default;
$dark-sidebar-nav-title-transition:
  height 0.15s,
  margin 0.15s !default;

$dark-sidebar-nav-link-padding-y: 0.8445rem !default;
$dark-sidebar-nav-link-padding-x: 1rem !default;
$dark-sidebar-nav-link-color: $dark-medium-emphasis-inverse !default;
$dark-sidebar-nav-link-bg: transparent !default;
$dark-sidebar-nav-link-transition:
  background 0.15s ease,
  color 0.15s ease !default;
$dark-sidebar-nav-link-icon-color: $dark-medium-emphasis-inverse !default;

$dark-sidebar-nav-link-hover-color: $dark-high-emphasis-inverse !default;
$dark-sidebar-nav-link-hover-bg: rgba($dark-white, 0.05) !default;
$dark-sidebar-nav-link-hover-icon-color: $dark-high-emphasis-inverse !default;

$dark-sidebar-nav-link-active-color: $dark-high-emphasis-inverse !default;
$dark-sidebar-nav-link-active-bg: rgba($dark-white, 0.05) !default;
$dark-sidebar-nav-link-active-icon-color: $dark-high-emphasis-inverse !default;

$dark-sidebar-nav-link-disabled-color: $dark-disabled-inverse !default;
$dark-sidebar-nav-link-disabled-icon-color: $dark-sidebar-nav-link-icon-color !default;

$dark-sidebar-nav-icon-width: 4rem !default;
$dark-sidebar-nav-icon-height: 1.25rem !default;
$dark-sidebar-nav-icon-font-size: $dark-sidebar-nav-icon-height !default;

$dark-sidebar-nav-group-bg: rgba(0, 0, 0, 0.2) !default;
$dark-sidebar-nav-group-transition: background 0.15s ease-in-out !default;
$dark-sidebar-nav-group-items-transition: height 0.15s ease !default;
$dark-sidebar-nav-group-toggle-show-color: $dark-sidebar-nav-link-color !default;

$dark-sidebar-nav-group-indicator-color: $dark-medium-emphasis-inverse !default;
$dark-sidebar-nav-group-indicator-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$dark-sidebar-nav-group-indicator-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$dark-sidebar-nav-group-indicator-hover-color: $dark-sidebar-nav-link-hover-color !default;
$dark-sidebar-nav-group-indicator-hover-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$dark-sidebar-nav-group-indicator-hover-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$dark-sidebar-nav-group-indicator-transition: transform 0.15s !default;

$dark-sidebar-footer-height: auto !default;
$dark-sidebar-footer-padding-y: 0.75rem !default;
$dark-sidebar-footer-padding-x: 1rem !default;
$dark-sidebar-footer-bg: rgba($dark-black, 0.2) !default;
$dark-sidebar-footer-height-transition:
  height 0.15s,
  padding 0.15s !default;

$dark-sidebar-toggler-height: 3rem !default;
$dark-sidebar-toggler-bg: rgba($dark-black, 0.2) !default;
$dark-sidebar-toggler-transition: transform 0.15s !default;

$dark-sidebar-toggler-indicator-width: 4rem !default;
$dark-sidebar-toggler-indicator-height: 3rem !default;
$dark-sidebar-toggler-indicator-color: $dark-gray-600 !default;
$dark-sidebar-toggler-indicator-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$dark-sidebar-toggler-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E") !default;
$dark-sidebar-toggler-hover-bg: rgba(0, 0, 0, 0.3) !default;
$dark-sidebar-toggler-indicator-hover-color: $dark-sidebar-nav-link-hover-color !default;
$dark-sidebar-toggler-indicator-hover-icon: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$dark-sidebar-toggler-indicator-hover-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E") !default;
// scss-docs-end sidebar-variables

// Footer
// scss-docs-start footer-variables
$dark-footer-min-height: 3rem !default;
$dark-footer-padding-y: calc($dark-spacer / 2) !default;
$dark-footer-padding-x: $dark-spacer !default;
$dark-footer-bg: $dark-gray-100 !default;
$dark-footer-color: $dark-body-color !default;
$dark-footer-border-width: 1px !default;
$dark-footer-border-color: $dark-border-color !default;
// scss-docs-end footer-variables

// Spinners
// scss-docs-start spinner-variables
$dark-spinner-width: 2rem !default;
$dark-spinner-height: $dark-spinner-width !default;
$dark-spinner-vertical-align: -0.125em !default;
$dark-spinner-border-width: 0.25em !default;
$dark-spinner-animation-speed: 0.75s !default;

$dark-spinner-width-sm: 1rem !default;
$dark-spinner-height-sm: $dark-spinner-width-sm !default;
$dark-spinner-border-width-sm: 0.2em !default;
// scss-docs-end spinner-variables

// Close
// scss-docs-start close-variables
$dark-btn-close-width: 1em !default;
$dark-btn-close-height: $dark-btn-close-width !default;
$dark-btn-close-padding-x: 0.25em !default;
$dark-btn-close-padding-y: $dark-btn-close-padding-x !default;
$dark-btn-close-color: $dark-high-emphasis !default;
$dark-btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$dark-btn-close-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") !default;
$dark-btn-close-focus-shadow: $dark-input-btn-focus-box-shadow !default;
$dark-btn-close-opacity: 0.5 !default;
$dark-btn-close-hover-opacity: 0.75 !default;
$dark-btn-close-focus-opacity: 1 !default;
$dark-btn-close-disabled-opacity: 0.25 !default;
$dark-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%) !default;
// scss-docs-end close-variables

// Offcanvas

// scss-docs-start offcanvas-variables
$dark-offcanvas-padding-y: $dark-modal-inner-padding !default;
$dark-offcanvas-padding-x: $dark-modal-inner-padding !default;
$dark-offcanvas-horizontal-width: 400px !default;
$dark-offcanvas-vertical-height: 30vh !default;
$dark-offcanvas-transition-duration: 0.3s !default;
$dark-offcanvas-border-color: $dark-modal-content-border-color !default;
$dark-offcanvas-border-width: $dark-modal-content-border-width !default;
$dark-offcanvas-title-line-height: $dark-modal-title-line-height !default;
$dark-offcanvas-bg-color: $dark-modal-content-bg !default;
$dark-offcanvas-color: $dark-modal-content-color !default;
$dark-offcanvas-box-shadow: $dark-modal-content-box-shadow-xs !default;
// scss-docs-end offcanvas-variables

// Code

$dark-code-font-size: $dark-small-font-size !default;
$dark-code-color: $dark-pink !default;

$dark-kbd-padding-y: 0.2rem !default;
$dark-kbd-padding-x: 0.4rem !default;
$dark-kbd-font-size: $dark-code-font-size !default;
$dark-kbd-color: $dark-high-emphasis-inverse !default;
$dark-kbd-bg: $dark-gray-900 !default;

$dark-pre-color: unset !default;
