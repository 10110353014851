.card {
  color: black;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.bg-light {
  background-color: rgb(6, 4, 4) !important;
}

.logo {
  width: 100px;
  height: auto;
}

.main {
  background-image: url('../../assets/backgroundA.avif');
  background-size: cover;
}

.context {
  /* display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; */
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(38, 56, 132, 0.3);
  border-radius: 3px;
}
